import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';

const App = () => {
    
  const [amount, setAmount] = useState(0);
  const [payload, setPayload] = useState('');
  const [b64payload, setb64Payload] = useState('');
  const [saltKey, setSaltKey] = useState('');
  const [saltIndex, setSaltIndex] = useState('');
  const [xKey, setXKey] = useState('');

  const base64Encode = (data) => {
    return btoa(data);
  };

  const sha256 = async (data) => {
    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(data);
    const hashBuffer = await crypto.subtle.digest('SHA-256', dataBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
  };

  const generateKey = async () => {
    
    const endpoint = '/pg/v1/pay';
    const b64Payload = base64Encode(payload);
    setb64Payload(b64Payload);
    const encodedPayload = b64Payload+endpoint+saltKey;
    const hashedValue = await sha256(encodedPayload);
    const generatedXKey = `${hashedValue}###${saltIndex}`;
    setXKey(generatedXKey);
  };

  useEffect(()=>{


  setPayload({
    "merchantId": "M227YAFIGKLAL",
    "merchantTransactionId": "850590068188104",
    "merchantUserId": "MUID123",
    "amount": amount,
    "redirectUrl": "https://webhook.site/redirect-url",
    "redirectMode": "REDIRECT",
    "callbackUrl": "https://webhook.site/callback-url",
    "mobileNumber": "9999999999",
    "paymentInstrument": {
      "type": "PAY_PAGE"
    }
  });
  setSaltKey('42453440-5f7f-4199-8edb-df2ab8d0baea');
  setSaltIndex('1');
  
},[amount]);


const sendData = async () => {
    const headers = {
        'accept': 'text/plain',
        'Content-Type': 'application/json',
        'X-VERIFY': xKey
    };

    try {
        const response = await axios.post('https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay/', payload, { headers } );
        console.log(response);
    } catch (error) {
        console.error(error);
    }
};

   
  return (
    <div class="p-4">
            <h5>API Test</h5>
            
            <input
                type="text"
                placeholder="Salt Key"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
            />
           
            <button onClick={generateKey}>Generate Key</button>
            <button onClick={sendData}>Test</button>
            {saltIndex && <p>saltIndex: {saltIndex}</p>}
            {b64payload && <p>payload: { b64payload}</p>}
            {saltKey && <p>saltKey: {saltKey}</p>}
            {xKey && <p>Generated xKey: {xKey}</p>}
        </div>
  );
};

export default App;
